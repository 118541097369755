import React, { useState ,useEffect} from 'react';
import {Form, Input, InputNumber, Popconfirm, Table, Typography ,message} from 'antd';
import { getCountries, getPriceSlabs,getZonalCountries,removePriceSlab, updateCountry, updatePriceSlab, updateZonalCountry } from '../service/apiFunction';
import moment from 'moment';
import PopConfirm from '../helperComponents/popConfirm';
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const App = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [total, setTotal] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const isEditing = (record) => record.countryId === editingKey;

  useEffect(() => {
    // This function runs after the first render
    fetchData();
  }, [currentPage]); // Empty dependency array means this effect runs only once after initial render

  const fetchData = async () => {
    getZonalCountries(currentPage,(data)=>{
        setData(data?.result);
        setTotal(data?.totalRecords);
        setPageSize(data?.pageSize);
    })
    // Example fetch operation
  };

  const goToPage =(pageNumber,pageSize)=>{
    cancel()
    setCurrentPage(pageNumber);
  }
  const edit = (record) => {
    form.setFieldsValue({
        countryName: '',
      ...record,
    });
    setEditingKey(record.countryId);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      row['countryId'] = key;
      setEditingKey('');
      updateZonalCountry(row,(updateResponse)=>{
        if(updateResponse){
            fetchData();
            message.success("country updated successfully.")
        }
        else{
            message.error("unable to update country");
        }
      })
    } catch (errInfo) {
        message.error("unable to update country");
    }
  };

  const remove =async(key) =>{
    removePriceSlab(key,async (isRemovedData)=>{
        if(isRemovedData){
            message.success('Price Slab removed successfully');
            await fetchData();    
        }
        else{
            message.error('Error in Removing Price Slab');
        }
    })
  }
  const columns = [
    {
      title: 'countryid',
      dataIndex: 'countryId',
      editable: false,
    },
    {
      title: 'countryname',
      dataIndex: 'countryName',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.countryId)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
            <>
          <Typography.Link disabled={editingKey !== ''} style={{
                marginRight: 8,
              }} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
         {/* <PopConfirm confirmed={(e)=>remove(e,record.countryId)}/> */}
          {/* <Popconfirm title="Sure to cancel?" onConfirm={remove(record.countryId)}>
              <a>Remove</a>
            </Popconfirm> */}
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange:(pageNumber,pageSize)=>goToPage(pageNumber,pageSize),
          pageSize : pageSize,
          total:total
        }}
      />
    </Form>
  );
};
export default App;