import {configureStore} from '@reduxjs/toolkit';
import { staticDataSlice } from './slices/staticData';

const store = configureStore({
  reducer: {
    staticData:staticDataSlice.reducer
  },
});

export default store;


