import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCountries,getAllOrderStatus,getAllPaymentStatus,getAllOrderStatusTracking, getAllZonalCountries } from './apis';

export const getAllCountriesThunk = createAsyncThunk(
  'staticData/getAllCountries',
  async (_, thunkAPI) => {
    try {
      const result = await getAllCountries();
      const optionList = result?.result?.map(country => ({
        value: country.countryId,
        label: country.countryName,
      }));
      return optionList;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllZonalCountriesThunk = createAsyncThunk(
  'staticData/getAllZonalCountries',
  async (_, thunkAPI) => {
    try {
      const result = await getAllZonalCountries();
      const optionList = result.map(country => ({
        value: country.countryId,
        label: country.countryName,
      }));
      return optionList;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllOrderStatusThunk = createAsyncThunk(
  'staticData/getAllOrderStatus',
  async (_, thunkAPI) => {
    try {
      const result = await getAllOrderStatus(); // Refactor getAllOrderStatus similarly
      return result.map(status => ({ value: status, label: status }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllPaymentStatusThunk = createAsyncThunk(
  'staticData/getAllPaymentStatus',
  async (_, thunkAPI) => {
    try {
      const result = await getAllPaymentStatus(); // Refactor getAllPaymentStatus similarly
      return result.map(status => ({ value: status, label: status }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllOrderStatusTrackingThunk = createAsyncThunk(
  'staticData/getAllOrderStatusTracking',
  async (_, thunkAPI) => {
    try {
      const result = await getAllOrderStatusTracking(); // Refactor getAllPaymentStatus similarly
      return result.map(status => ({ value: status, label: status }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
