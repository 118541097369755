export const  numericValueRegex=(string)=> {
  return string.replace(/[^0-9]/g, '');
  }
  
  export const removeNonNumerics = (str) => {
    return str.replace(/[^0-9]/g, "");
  }

  export const removeNonAlphabets=(str) =>{
    return str.replace(/[^a-zA-Z]/g, "");
  }
  export const isAlphabets = (str) => {
    return /^[a-zA-Z]+$/.test(str);
  };