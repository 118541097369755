import React, { useState ,useEffect} from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import Users from '../antdComponents/users'
import Orders from '../antdComponents/orders';
import PriceSlab from '../antdComponents/priceslabs';
import PriceSlabForZone from '../antdComponents/priceSlabsForZone';
import AddPrieSlab from '../antdComponents/addPriceSlab';
import AddZonalPrieSlab from '../antdComponents/addZonalPriceSlab';
import Countries from '../antdComponents/countries';
import ZonalCountries from '../antdComponents/zonalCountries';
import AddCountries from '../antdComponents/addCountries';
import AddZonalCountries from '../antdComponents/addZonalCountries';
import Zones from '../antdComponents/zones';
import AddZone from '../antdComponents/addZone';
import ZonalZipcodes from '../antdComponents/zonalZipcodes';
import AddZonalZipcodes from '../antdComponents/addZonalZipcodes';
import { getAllCountriesThunk,getAllOrderStatusThunk,getAllPaymentStatusThunk,getAllOrderStatusTrackingThunk } from '../redux/slices/staticData/asyncThunks';
import { useDispatch } from 'react-redux';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('Users', '1', <PieChartOutlined />),
  getItem('Orders', '2', <DesktopOutlined />),
  getItem('PriceSlabs', 'sub1', <UserOutlined />, [
    getItem('PriceSlabs', '3'),
    getItem('PriceSlabsForZone', '4'),
    getItem('AddPriceSlab', '5'),
    getItem('AddPriceSlabForZone', '6'),
  ]),
  getItem('Countries', 'sub2', <TeamOutlined />, [
    getItem('Countries', '7'),
    getItem('ZonalCountries', '8'),
    getItem('AddCountries', '9'),
    getItem('AddZonalCountries', '10'),
  ]),
  getItem('Zones', 'sub3', <FileOutlined />,[
    getItem('Zones', '11'),
    getItem('ZonalZipcodes', '12'),
    getItem('AddZone', '13'),
    getItem('AddZonalZipcodes', '14'),
  ]),
  getItem('Logout', '15', <DesktopOutlined />),
];

const App = ({onLogout}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('1'); // State to track selected menu item
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCountriesThunk());
    dispatch(getAllOrderStatusThunk());
    dispatch(getAllOrderStatusTrackingThunk());
    dispatch(getAllPaymentStatusThunk());
    //dispatch(getAllZonalCountriesThunk());
}, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleMenuClick = (e) => {
    if (e.key === '15') {
      onLogout();
    }
    else{
      setSelectedMenuItem(e.key);
    }
  };

  const getMenuItems = () => {
    return items.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
            {item.children.map((subItem) => (
              <Menu.Item key={subItem.key}>{subItem.label}</Menu.Item>
            ))}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label}
          </Menu.Item>
        );
      }
    });
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return (
          <Users />
        );
      case '2':
        return (
          <Orders/>
        );
      case '3':
        return (
            <PriceSlab/>
        );
      case '4':
        return (
          <PriceSlabForZone />
        );
      case '5':
        return (
          <AddPrieSlab />
        );
      case '6':
        return (
          <AddZonalPrieSlab/>
        );
      case '7':
        return (
          <Countries/>
        );
      case '8':
        return (
          <ZonalCountries/>
        );
      case '9':
        return (
          <AddCountries/>
        );
      case '10':
        return (
          <AddZonalCountries/>
        );
      case '11':
        return (
          <Zones/>
        );
      case '12':
        return (
          <ZonalZipcodes/>
        );
      case '13':
        return (
          <AddZone/>
        );
      case '14':
        return (
          <AddZonalZipcodes/>
        );
      default:
        return null;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          onClick={handleMenuClick}
          selectedKeys={[selectedMenuItem]}
        >
          {getMenuItems()}
        </Menu>
      </Sider>
      <Layout>
        {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        /> */}
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {items.map((item) => {
              if (item.children) {
                const subItem = item.children.find((sub) => sub.key === selectedMenuItem);
                if (subItem) {
                  return (
                    <Breadcrumb.Item key={subItem.key}>{subItem.label}</Breadcrumb.Item>
                  );
                }
              } else if (item.key === selectedMenuItem) {
                return (
                  <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
                );
              }
              return null;
            })}
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {renderContent()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
