export const hasValidValues = (dataArray) => {
    for (let i = 0; i < dataArray.length; i++) {
      const obj = dataArray[i];
      // Check each field except 'key' for empty string or 0
      for (let key in obj) {
        if (key !== 'key' && (obj[key] === "" || obj[key] === 0)) {
          return false; // Return true if any field is empty or 0
        }
      }
    }
    return true; // Return false if all fields are valid
  };