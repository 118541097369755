const base_url ='https://www.wingz4uapi.com';

const api = {
    getUsers:base_url+'/api/admin/getusers',
    getOrders:base_url+'/api/admin/getorders',
    getTransactions:base_url+'/api/admin/gettransactions',
    getCountries:base_url+'/api/admin/getcountries',
    getAllCountries:base_url+'/api/admin/getallcountries',
    getZonalCountries :base_url + '/api/admin/getzonalcountries',
    getAllZonalCountries :base_url + '/api/admin/getallzonalcountries',
    getZones:base_url +  '/api/admin/getzones',
    getAllZones:base_url +  '/api/admin/getallzones',
    getZonalZipcodes :base_url + '/api/admin/getzonalzipcodes',
    getPriceSlabs : base_url + '/api/admin/getpriceslabs',
    getZonalPriceSlabs : base_url + '/api/admin/getzonalpriceslabs',
    getWeightSlabs : base_url + '/api/admin/getweightslabs',
    getAllWeightSlabs : base_url + '/api/admin/getallweightslabs',
    getOrderDetails:base_url +'/api/admin/getorderdetails',
    getAllPaymentStatus:base_url +'/api/admin/getallpaymentstatus',
    getAllOrderStatus:base_url +'/api/admin/getallorderstatus',
    getAllOrderStatusTracking:base_url +'/api/admin/getallordertrackingstatus',
    updateUser:base_url+'/api/admin/updateuser',
    updateCountry:base_url+'/api/admin/updatecountry',
    updateZonalCountry:base_url+'/api/admin/updatezonalcountry',
    updatePriceSlab:base_url+'/api/admin/updatepriceslab',
    updateZonalPriceSlab:base_url+'/api/admin/updatezonalpriceslab',
    updateOrder:base_url+'/api/admin/updateorder',
    addZone :base_url + '/api/admin/addzone',
    addCountries :base_url + '/api/admin/addcountries',
    addZonalCountries :base_url + '/api/admin/addzonalcountries',
    addZonalZipcode:base_url+'/api/admin/addzonalzipcodes', 
    addPriceSlab:base_url +'/api/admin/addpriceslabs',
    addZonalPriceSlab:base_url +'/api/admin/addzonalpriceslabs',
    removePriceSlab:base_url+'/api/admin/removepriceslab',
    removeZonalPriceSlab:base_url+'/api/admin/removezonalpriceslab',
    removeZone:base_url+'/api/admin/removezone',
    removeZonalZipcode:base_url+'/api/admin/removezonalzipcode',
    assignAWBNO:base_url + '/api/admin/assignawb'
}


export default api;
