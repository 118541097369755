import React, { useState ,useEffect} from 'react';
import { Table, message ,Popconfirm,Button} from 'antd';
import { getZonalZipCodes,getZones, removeZonalZipcode, removeZone } from '../service/apiFunction';

const App = () =>{
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        // This function runs after the first render
        fetchData();
      }, [currentPage]); // Empty dependency array means this effect runs only once after initial render
    
      const fetchData = async () => {
        getZonalZipCodes(currentPage,(data)=>{
            setData(data?.result);
            setTotal(data?.totalRecords);
            setPageSize(data?.pageSize);
          })
        // Example fetch operation
      };
      const remove =async(e,key) =>{
        removeZonalZipcode(key,async (isRemovedData)=>{
            if(isRemovedData){
                message.success('Zipcode removed successfully');
                await fetchData();    
            }
            else{
                message.error('Error in Removing Zipcode');
            }
        })
      }
    const goToPage =(pageNumber,pageSize)=>{
        setCurrentPage(pageNumber);
      }
      const columns = [
        {
          title: 'zipcodeid',
          dataIndex: 'zipcodeId'
        },
        {
          title: 'zonename',
          dataIndex: 'zoneName',
          render: (_, record) => {
            return (<>{record.zones.zoneName}</>)},
        },
        {
          title: 'zipcode',
          dataIndex: 'zipcode'
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) =>
                data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={(e) => remove(e,record.zipcodeId)}>
                       <Button size='small' danger>Delete</Button>
                    </Popconfirm>
                ) : null,
        },
      ];
    return (
<Table 
      columns={columns}
       dataSource={data} 
       bordered
       pagination={{
        onChange:(pageNumber,pageSize)=>goToPage(pageNumber,pageSize),
        pageSize : pageSize,
        total:total
      }}
      />)
} 
export default App;