import { Avatar, Typography, Col, Divider, Drawer, Select, List, Row, Button, Popconfirm, Input ,message} from 'antd';
import { Component, useState } from 'react';
import './OrderDetails.css'
import { assignAWBNO, orderDetails, updateOrder } from '../service/apiFunction';
import { numericValueRegex } from '../service/regex';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { connect, dispatch } from "react-redux";
import { getAllCountriesThunk, getAllOrderStatusThunk, getAllPaymentStatusThunk, getAllOrderStatusTrackingThunk } from '../redux/slices/staticData/asyncThunks';

class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderDetails: {},
      editedOrderDetails: {},
      showAddAWBForm: false,
      awbNumber: '',
      isEdit: false
    }
  }

  componentDidMount() {
    const {
      countriesOptions,
      orderStatusOptions,
      paymentStatusOptions,
      orderStatusTrackingOptions,
      getAllCountriesThunk,
      getAllOrderStatusThunk,
      getAllPaymentStatusThunk,
      getAllOrderStatusTrackingThunk
    } = this.props;

    // Fetch data if not already present
    if (!countriesOptions.length) {
      getAllCountriesThunk();
    }
    if (!orderStatusOptions.length) {
      getAllOrderStatusThunk();
    }
    if (!paymentStatusOptions.length) {
      getAllPaymentStatusThunk();
    }
    if (!orderStatusTrackingOptions.length) {
      getAllOrderStatusTrackingThunk();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderId !== this.props.orderId && this.props.orderId !== 0) {
      this.fetchData()
    }
  }
  
  fetchData = ()=>{
    orderDetails(this.props.orderId, (data) => {
      if (data) {
        this.setState({ orderDetails: data, editedOrderDetails: cloneDeep(data),isEdit: false});
      }
    })
  }

  assignAWBNOApiCall = () => {
    assignAWBNO(this.props.orderId, this.state.awbNumber.toString(), (updateResponse) => {
      if(updateResponse){
        message.success("order updated successfully.");
        this.fetchData();
    }
    else{
        message.error("unable to update orderb");
    }
    })
    this.setState({ awbNumber: '' })
  }

  handleUnits = async (e, key) => {
    if ((parseFloat(e.target.value) <= 0.5) && key !== 'packageDetails.packageWeight') {

      return;
    }
    else {
      const { editedOrderDetails } = this.state;
      const updatedOrderDetails = { ...editedOrderDetails };
      const keys = key.split('.');
      let obj = updatedOrderDetails;
      for (let i = 0; i < keys.length - 1; i++) {
        obj = obj[keys[i]];
      }
      obj[keys[keys.length - 1]] = parseFloat(e.target.value);
      this.setState({ editedOrderDetails: updatedOrderDetails }, async () => {
        await this.changeUnits();
      });
    }
  }

  changeUnits = async () => {
    const { editedOrderDetails } = this.state;

    if (editedOrderDetails.packageDetails?.height !== "" && editedOrderDetails.packageDetails?.breadth !== "" && editedOrderDetails.packageDetails?.length !== "") {
      this.setState((prevState) => {
        const updatedEditedOrderDetails = {
          ...prevState.editedOrderDetails,
          packageDetails: {
            ...prevState.editedOrderDetails.packageDetails,
            volumetricWeight: ((parseFloat(prevState.editedOrderDetails.packageDetails?.height) * parseFloat(prevState.editedOrderDetails.packageDetails?.breadth) * parseFloat(prevState.editedOrderDetails.packageDetails?.length)) / 5000).toString()
          },
        };

        if ((updatedEditedOrderDetails.packageDetails?.packageWeight !== "" && updatedEditedOrderDetails.packageDetails?.volumetricWeight !== "") && (parseFloat(updatedEditedOrderDetails.packageDetails?.packageWeight) > parseFloat(updatedEditedOrderDetails.packageDetails?.volumetricWeight))) {
          const updatedChargeableEditedOrderDetails = {
            ...updatedEditedOrderDetails,
            packageDetails: {
              ...updatedEditedOrderDetails.packageDetails,
              chargeableWeight: updatedEditedOrderDetails.packageDetails?.packageWeight,
            },
          };
          return {
            editedOrderDetails: updatedChargeableEditedOrderDetails
          }
        }
        else {
          const updatedChargeableEditedOrderDetails = {
            ...updatedEditedOrderDetails,
            packageDetails: {
              ...updatedEditedOrderDetails.packageDetails,
              chargeableWeight: updatedEditedOrderDetails.packageDetails?.volumetricWeight,
            },
          };

          return {
            editedOrderDetails: updatedChargeableEditedOrderDetails
          }
        }
      })
    }
    else {
      this.setState((prevState) => {
        const updatedChargeableEditedOrderDetails = {
          ...prevState.editedOrderDetails,
          packageDetails: {
            ...prevState.editedOrderDetails.packageDetails,
            chargeableWeight: "",
            volumetricWeight: "",
          },
        };
        return {
          editedOrderDetails: updatedChargeableEditedOrderDetails
        }
      })
    }
  }

  handleInput = (event) => {
    let digit = numericValueRegex(event.target.value);
    this.setState({ awbNumber: digit });
  }
  assignAWBNOItem = () => {
    return (
      <>
        <Row>
          <Col span={12} >
          {/* <Input
              
              defaultValue={this.state.awbNumber}
              onChange={(e) => this.handleInput(e)}
            /> */}
            <input type="number" id="numericInput" value={this.state.awbNumber} placeholder="awb number" name="numericInput" onChange={(event) => { this.handleInput(event) }} />
            {/* <input value={this.state.awbNumber} placeholder="AWB NO" onChange={(event) => { this.handleInput(event) }} /> */}
          </Col>
          <Col span={12} >
            <Popconfirm
              title="Confirm Dialogue"
              description={`Set this AWB For ${this.props.orderId}, Please Confirm`}
              onConfirm={() => this.assignAWBNOApiCall()}
              onOpenChange={() => console.log('open change')}
            >
              <Button type="primary">Assign AWB NO</Button>
            </Popconfirm>
          </Col>
        </Row>
        
      </>
    )
  }

  toggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit })
    this.forceUpdate();
  }

  cancelEdit = () => {
    this.toggleEdit();
    this.setState({ editedOrderDetails: cloneDeep(this.state.orderDetails) })
  }
  saveEdit = () => {
    const { editedOrderDetails, orderDetails } = this.state;
    const { orderStatusTrackings: editedOrderStatusTrackings } = editedOrderDetails;
    const { orderStatusTrackings: originalOrderStatusTrackings } = orderDetails;

    let obj = { ...editedOrderDetails }; // create a new object

    if (editedOrderStatusTrackings.orderStatus !== originalOrderStatusTrackings.orderStatus) {
      obj.orderStatusTrackings.lastStatus = originalOrderStatusTrackings.orderStatus;
    }
    updateOrder(obj,(updateResponse)=>{
      if(updateResponse){
        message.success("order updated successfully.");
        this.fetchData();
    }
    else{
        message.error("unable to update orderb");
    }
    })

  }
  closeDrawer = () => {
    this.setState({ awbNumber: '', showAddAWBForm: false, isEdit: false });

    this.props.closeDrawer();
  }

  DescriptionItem = (props) => {
    return (
      this.state.isEdit === true && props.isEditable === true ?
        (
          <div className="site-description-item-profile-wrapper">
            <p className="site-description-item-profile-p-label">{props.title}:</p>
            <Input
              defaultValue={props.content}
              onChange={props.onChange}
            />
          </div>
        )
        :
        (
          <div className="site-description-item-profile-wrapper">
            <p className="site-description-item-profile-p-label">{props.title}:</p>
            {props.content}
          </div>
        )
    );
  };

  SelectDescriptionItem = (props) => {
    return (
      this.state.isEdit === true && props.isEditable === true ?
        (
          <div className="site-description-item-profile-wrapper">
            <p className="site-description-item-profile-p-label">{props.title}:</p>
            <Select
              showSearch
              placeholder={props.title}
              style={{
                width: 140,
              }}
              // optionFilterProp="children"
              onChange={(val, option) => props.onChange(option.label)}

              // onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={props.content}
              options={props.options}
            />
          </div>
        )
        :
        (
          <div className="site-description-item-profile-wrapper">
            <p className="site-description-item-profile-p-label">{props.title}:</p>
            {props.content}
          </div>
        )
    );
  };

  // handleInputChange method
  handleInputChange = (e, key) => {
    const { editedOrderDetails } = this.state;
    const updatedOrderDetails = { ...editedOrderDetails };
    const keys = key.split('.');
    let obj = updatedOrderDetails;
    for (let i = 0; i < keys.length - 1; i++) {
      obj = obj[keys[i]];
    }
    obj[keys[keys.length - 1]] = e.target.value;
    this.setState({ editedOrderDetails: updatedOrderDetails });
  };

  handleSelectInputChange = (val, key) => {
    const { editedOrderDetails } = this.state;
    const updatedOrderDetails = { ...editedOrderDetails };
    const keys = key.split('.');
    let obj = updatedOrderDetails;
    for (let i = 0; i < keys.length - 1; i++) {
      obj = obj[keys[i]];
    }
    obj[keys[keys.length - 1]] = val;
    this.setState({ editedOrderDetails: updatedOrderDetails });
  };

  render() {
    const orderDetails = this.state.isEdit ? this.state.editedOrderDetails : this.state.orderDetails;
    return (
      <Drawer title={
        <>
          <span>Order Details</span>
          {this.state.isEdit ? (
            <span style={{ float: 'right' }}>
              <Typography.Link
                onClick={this.saveEdit}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={this.cancelEdit}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link style={{
              marginRight: 8,
              float: 'right'
            }} onClick={this.toggleEdit}>
              Edit
            </Typography.Link>
          )}
          {/* <Button
              style={{ float: 'right' }}
              icon={this.state.isEdit ? 'save' : 'edit'}
              onClick={this.toggleEdit}
            /> */}
        </>
      } width={940} placement="right" closable={true} onClose={this.closeDrawer} open={this.props.drawerStatus}>
        {orderDetails.orderStatusTrackings?.awbNumber ? null :
          this.state.showAddAWBForm ? this.assignAWBNOItem() :
            <Row>
              <Col span={12} ></Col>
              <Col span={12} ><Button onClick={() => this.setState({ showAddAWBForm: true })} type="primary">Assign AWB NO</Button></Col>
            </Row>
        }
        {/* General details */}
        <p className="site-description-item-profile-p">General Details</p>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Order Id" content={orderDetails.orderId} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="User Id" content={orderDetails.userId} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Delivery Provider" content={orderDetails.deliveryProvider} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Total Amount" content={orderDetails.amount}
              onChange={(e) => this.handleInputChange(e, 'amount')} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Date Created" content={moment(orderDetails.dateCreated).format('llll')} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Bill To Delivery Address" content={orderDetails.billingToDeliveryAddress} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.SelectDescriptionItem
              isEditable={true}
              title="Order Status"
              content={orderDetails.orderStatus}
              options={this.props.orderStatusOptions}
              onChange={(val) => this.handleSelectInputChange(val, 'orderStatus')}
            />
          </Col>
        </Row>
        <Divider />
        {/* Package details */}
        <p className="site-description-item-profile-p">Pickup Details</p>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Name" content={orderDetails.userDetails?.name}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.name')} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Email" content={orderDetails.userDetails?.email}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.email')} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Phone No" content={orderDetails.userDetails?.phoneNo}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.phoneNo')} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Pickup Zipcode" content={orderDetails.pickupDetails?.pickupZipcode}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.pickupZipcode')} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Latitude" content={orderDetails.pickupDetails?.latitude}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.latitude')} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Longitude" content={orderDetails.pickupDetails?.longitude}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.longitude')} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.DescriptionItem isEditable={true}
              title="Pickup Address"
              content={orderDetails.pickupDetails?.pickupAddress}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.pickupAddress')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true}
              title="Pickup City"
              content={orderDetails.pickupDetails?.pickupCity}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.pickupCity')}
            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem
              isEditable={true}
              title="Pickup State"
              content={orderDetails.pickupDetails?.pickupState}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.pickupState')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.SelectDescriptionItem
              isEditable={true}
              title="Pickup Country"
              content={orderDetails.pickupDetails?.pickupCountry}
              options={this.props.countriesOptions}
              onChange={(val) => this.handleSelectInputChange(val, 'pickupDetails.pickupCountry')}
            />
          </Col>

        </Row>
        <Row>
          <Col span={24}>
            <this.DescriptionItem isEditable={true}
              title="Pickup Geolocation"
              content={orderDetails.pickupDetails?.pickupGeolocationAddress}
              onChange={(e) => this.handleInputChange(e, 'pickupDetails.pickupGeolocationAddress')}
            />
          </Col>
        </Row>
        <Divider />

        {/* Delivery details */}
        <p className="site-description-item-profile-p">Delivery Details</p>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Name" content={orderDetails.deliveryDetails?.name}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.name')}
            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Email" content={orderDetails.deliveryDetails?.email}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.email')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Phone No" content={orderDetails.deliveryDetails?.phoneNo}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.phoneNo')}
            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Delivery Zipcode" content={orderDetails.deliveryDetails?.deliveryZipcode}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.deliveryZipcode')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Latitude" content={orderDetails.deliveryDetails?.latitude}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.latitude')}
            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true} title="Longitude" content={orderDetails.deliveryDetails?.longitude}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.longitude')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.DescriptionItem isEditable={true}
              title="Delivery Address"
              content={orderDetails.deliveryDetails?.deliveryAddress}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.deliveryAddress')}

            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={true}
              title="Delivery City"
              content={orderDetails.deliveryDetails?.deliveryCity}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.deliveryCity')}

            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={true}
              title="Delivery State"
              content={orderDetails.deliveryDetails?.deliveryState}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.deliveryState')}

            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.SelectDescriptionItem
              isEditable={true}
              title="Delivery Country"
              content={orderDetails.deliveryDetails?.deliveryCountry}
              options={this.props.countriesOptions}
              onChange={(val) => this.handleSelectInputChange(val, 'deliveryDetails.deliveryCountry')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.DescriptionItem isEditable={true}
              title="Delivery Geolocation"
              content={orderDetails.deliveryDetails?.deliveryGeolocationAddress}
              onChange={(e) => this.handleInputChange(e, 'deliveryDetails.deliveryGeolocationAddress')}

            />
          </Col>
        </Row>
        <Divider />

        {/* Package details */}
        <p className="site-description-item-profile-p">Package Details</p>
        <Row>
          <Col span={8}>
            <this.DescriptionItem isEditable={true} title="Length" content={orderDetails.packageDetails?.length}
              onChange={(e) => this.handleUnits(e, 'packageDetails.length')}
            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={true} title="Breadth" content={orderDetails.packageDetails?.breadth}
              onChange={(e) => this.handleUnits(e, 'packageDetails.breadth')}
            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={true} title="Height" content={orderDetails.packageDetails?.height}
              onChange={(e) => this.handleUnits(e, 'packageDetails.height')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <this.DescriptionItem isEditable={true} title="Package Weight" content={orderDetails.packageDetails?.packageWeight}
              onChange={(e) => this.handleUnits(e, 'packageDetails.packageWeight')}
            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={false} title="Chargeable Weight" content={orderDetails.packageDetails?.chargeableWeight}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.chargeableWeight')}
            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={false} title="Volumetric Weight" content={orderDetails.packageDetails?.volumetricWeight}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.volumetricWeight')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <this.DescriptionItem isEditable={true}
              title="Product Name"
              content={orderDetails.packageDetails?.productName}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.productName')}
            />
          </Col>
          <Col span={16}>
            <this.DescriptionItem isEditable={true}
              title="Product Sku "
              content={orderDetails.packageDetails?.productSku}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.productSku')}

            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <this.DescriptionItem isEditable={true}
              title="Product Quantity"
              content={orderDetails.packageDetails?.productQuantity}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.productQuantity')}

            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={true}
              title="Product Cost Per Quantity"
              content={orderDetails.packageDetails?.productCostPerQuantity}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.productCostPerQuantity')}

            />
          </Col>
          <Col span={8}>
            <this.DescriptionItem isEditable={true}
              title="Package Cost"
              content={orderDetails.packageDetails?.packageCost}
              onChange={(e) => this.handleInputChange(e, 'packageDetails.packageCost')}

            />
          </Col>
        </Row>
        <Divider />

        {/* Order Status details */}
        <p className="site-description-item-profile-p">Order Status Tracking</p>
        <Row>
          <Col span={12}>
            <this.SelectDescriptionItem
              isEditable={true}
              title="Order Status"
              content={orderDetails.orderStatusTrackings?.orderStatus}
              options={this.props.orderStatusTrackingOptions}
              onChange={(val) => this.handleSelectInputChange(val, 'orderStatusTrackings.orderStatus')}
            />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Last Status" content={orderDetails.orderStatusTrackings?.lastStatus} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {orderDetails.orderStatusTrackings?.awbNumber ?
              <this.DescriptionItem isEditable={true} title="AWB NO" content={orderDetails.orderStatusTrackings?.awbNumber} />
              :
              null}
          </Col>
        </Row>
        <Divider />

        {/* Transaction details */}
        <p className="site-description-item-profile-p">Transaction Details</p>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Payment Type" content={orderDetails.transactions?.paymentType} />
          </Col>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Total Amount(Inc GST)" content={orderDetails.transactions?.amount} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.SelectDescriptionItem
              isEditable={true}
              title="Payment Status"
              content={orderDetails.transactions?.paymentStatus}
              options={this.props.paymentStatusOptions}
              onChange={(val) => this.handleSelectInputChange(val, 'transactions.paymentStatus')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Payment Gateway Id" content={orderDetails.transactions?.paymentGatewayPaymentId} />
          </Col>

          <Col span={12}>
            <this.DescriptionItem isEditable={false} title="Payment Order Id" content={orderDetails.transactions?.paymentGatewayOrderId} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <this.DescriptionItem isEditable={false} title="Payment Gateway Signature" content={orderDetails.transactions?.paymentGatewaySignature} />
          </Col>
        </Row>
        <Divider />
      </Drawer>

    );
  }
};


const mapStateToProps = (state) => {
  return {
    countriesOptions: state.staticData.countriesOptions,
    orderStatusOptions: state.staticData.orderStatusOptions,
    paymentStatusOptions: state.staticData.paymentStatusOptions,
    orderStatusTrackingOptions: state.staticData.orderStatusTrackingOptions,
    // loading: state.loading,
  };
};

// map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountriesThunk: () => dispatch(getAllCountriesThunk()),
    getAllOrderStatusThunk: () => dispatch(getAllOrderStatusThunk()),
    getAllPaymentStatusThunk: () => dispatch(getAllPaymentStatusThunk()),
    getAllOrderStatusTrackingThunk: () => dispatch(getAllOrderStatusTrackingThunk())
  };
};
// connect the component to the store
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);