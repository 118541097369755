import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Select, Popconfirm, Table, message } from 'antd';
import { addPriceSlabs, getAllCountries, getAllWeight } from '../service/apiFunction';
import { hasValidValues } from '../service/helpers';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
        }
    };


    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
const App = () => {
    const initialData = [
        {
            key: 0,
            countryId: 0,
            countryName: '',
            weightId: 0,
            weight: 0,
            wingzPrice: 0,
            dhlPrice: 0,
            fedexPrice: 0,
        }
    ]
    const [dataSource, setDataSource] = useState(initialData);
    const [count, setCount] = useState(1);
    const [countriesOptions, setCountriesOptions] = useState([{
        value: '',
        label: '',
    }]);
    const [weightOptions, setWeightOptions] = useState([{
        value: '',
        label: '',
    }]);
    useEffect(() => {
        setCountriesOptionsFunc();
        setWeightOptionsFunc();
    }, [])
    const setCountriesOptionsFunc = () => {
        getAllCountries((data) => {
            var result = data?.result;
            var optionList = [];
            for (let i = 0; i < result.length; i++) {
                var options = {
                    value: result[i].countryId,
                    label: result[i].countryName,
                }
                optionList.push(options);
            }
            if (optionList.length > 0) {
                setCountriesOptions(optionList);
            }
        })
    }

    const setWeightOptionsFunc = () => {
        getAllWeight((data) => {
            var result = data?.result;
            var optionList = [];
            for (let i = 0; i < result.length; i++) {
                var options = {
                    value: result[i].weightId,
                    label: result[i].weight,
                }
                optionList.push(options);
            }
            if (optionList.length > 0) {
                setWeightOptions(optionList);

            }
        })

    }

    const addDataToDB = (e) => {
        let validateData = hasValidValues(dataSource);
        if (validateData) {
            addPriceSlabs(dataSource, (res) => {
                if (res) {
                    message.success('price slabs added successfully');
                    setDataSource([]);
                    setDataSource(initialData);
                }
                else{
                 message.error('error in adding price slabs!!')

                }
            })
        }
        else {
            message.error('please fill all details')
        }
        // dataSource
    }
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);

    };


    const onChange = (obj) => {
        //let toUpdateData = dataSource.filter((item) => item.key === key);
        // setDataSource(newData);
        //alert(`selected ${value}`);
        let data = [...dataSource];
        let values = obj.values;
        data.map((item, index) => {
            if (item.key === obj.key) {
                for (let key in values) {
                    item[key] = values[key];
                }
            }
        });
        setDataSource(data);
    };
    //   const options = [
    //     { value: 1, label: 'USA' },
    //     { value: 2, label: 'Canada' },
    //     { value: 3, label: 'UK' },
    //   ];
    const defaultColumns = [
        {
            title: 'country',
            dataIndex: 'countryName',
            editable: false,
            render: (index, record) => {
                return (<Select
                    showSearch
                    placeholder="countries"
                    optionFilterProp="children"
                    onChange={(val, option) => onChange({
                        values: {
                            countryName: option.label,
                            countryId: val,
                        },
                        key: record.key,
                    })}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={countriesOptions}
                />)
            },
        },
        {
            title: 'weight',
            dataIndex: 'weightId',
            editable: false,
            render: (index, record) => {
                return (<Select
                    showSearch
                    placeholder="weight"
                    // optionFilterProp="children"
                    onChange={(val, option) => onChange({
                        values: {
                            weight: option.label,
                            weightId: val,
                        },
                        key: record.key,
                    })}
                    // onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    // }
                    options={weightOptions}
                />)
            },

        },
        {
            title: 'wingzprice',
            dataIndex: 'wingzPrice',
            editable: true,

        },
        {
            title: 'dhlprice',
            dataIndex: 'dhlPrice',
            editable: true,

        },
        {
            title: 'fedexprice',
            dataIndex: 'fedexPrice',
            editable: true,

        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ];
    const handleAdd = () => {
        const newData = {
            key: count,
            countryId: 0,
            countryName: '',
            weightId: 0,
            weight: 0,
            wingzPrice: 0,
            dhlPrice: 0,
            fedexPrice: 0,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                    marginRight: 10
                }}
            >
                Add a row
            </Button>
            <Popconfirm title="Sure to add?" onConfirm={(e) => addDataToDB(e)}>
            <Button type="primary">save data</Button>
                    </Popconfirm>
            {/* <Button
                onClick={(e) => addDataToDB(e)}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                save data
            </Button> */}
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
        </div>
    );
};
export default App;