import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCountriesThunk,
  getAllZonalCountriesThunk,
  getAllPaymentStatusThunk,
  getAllOrderStatusThunk,
  getAllOrderStatusTrackingThunk,
} from './asyncThunks';
import { INITIAL_STATE } from './initial-state';

export const staticDataSlice = createSlice({
  name: 'staticData',
  initialState: INITIAL_STATE,
  reducers: {
    clearStaticData: (state) => {
      return INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCountriesThunk.fulfilled, (state, { payload }) => {
      state.countriesOptions = payload;
    });
    builder.addCase(getAllZonalCountriesThunk.fulfilled, (state, { payload }) => {
      state.zonalCountriesOptions = payload;
    });
    builder.addCase(getAllPaymentStatusThunk.fulfilled, (state, { payload }) => {
      state.paymentStatusOptions = payload;
    });
    builder.addCase(getAllOrderStatusThunk.fulfilled, (state, { payload }) => {
      state.orderStatusOptions = payload;
    });
    builder.addCase(getAllOrderStatusTrackingThunk.fulfilled, (state, { payload }) => {
      state.orderStatusTrackingOptions = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearStaticData } = staticDataSlice.actions;