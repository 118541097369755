import React,{useState,useEffect} from 'react';
import { Table, Tag,Select ,Popconfirm,Button,message} from 'antd';
import { hasValidValues } from '../service/helpers';
import { addZone, getAllZonalCountries } from '../service/apiFunction';

const App = () => { 
    const initialData = [
        {
            key: 0,
            countryId:0,
            countryName: ''
        }
    ]
    const [dataSource, setDataSource] = useState(initialData);
    const [zonalCountriesOptions, setZonalCountriesOptions] = useState([{
        value: '',
        label: '',
    }]);

    useEffect(() => {
        setCountriesOptionsFunc();
    }, [])
    
    const setCountriesOptionsFunc = () => {
        getAllZonalCountries((data) => {
            var result = data?.result;
            var optionList = [];
            for (let i = 0; i < result.length; i++) {
                var options = {
                    value: result[i].countryId,
                    label: result[i].countryName,
                }
                optionList.push(options);
            }
            if (optionList.length > 0) {
                setZonalCountriesOptions(optionList);
            }
        })
    }

    const onChange = (obj) => {
        let data = [...dataSource];
        data[0].countryId = obj.values.countryId;
        data[0].countryName = obj.values.countryName;

        setDataSource(data);
    };
    const addDataToDB = (e) => {
        let validateData = hasValidValues(dataSource);
        
        if (validateData) {
            addZone(dataSource[0], (res) => {
                if (res) {
                    message.success('zone added successfully');
                    setDataSource(initialData);
                }
                else{
                 message.error('error in adding zone!!')

                }
            })
        }
        else {
            message.error('please fill all details')
        }
        // dataSource
    }
    const columns = [
        {
          title: 'zonename',
          dataIndex: 'zoneName',
          render: () => {
              return (<Select
                  showSearch
                  placeholder="countries"
                  optionFilterProp="children"
                  onChange={(val, option) => onChange({
                    values: {
                        countryName: option.label,
                        countryId: val,
                    },
                })}
                  filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={zonalCountriesOptions}
              />)
          },
        },
        {
          title: 'Action',
          key: 'action',
          render: () => (
              <Popconfirm title="Sure to add?" onConfirm={(e) => addDataToDB(e)}>
              <Button type="primary">save data</Button>
              </Popconfirm>
          ),
        },
      ];
    return (<Table bordered columns={columns} dataSource={dataSource} pagination={false}/>)}
export default App;