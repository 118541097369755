import axios from 'axios';

// import {getUniqueId, getVersion} from 'react-native-device-info';
import api from './api';
// const getConfig = async () => ({
//   headers: {
//     // 'device-type': Platform.OS,
//     // 'device-id': getUniqueId(),
//     // 'app-version': getVersion(),
//     authorization:await getUserToken()//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiVGh1IEFwciAyMCAyMDIzIDIxOjQ3OjAxIEdNVCswMDAwIChDb29yZGluYXRlZCBVbml2ZXJzYWwgVGltZSkiLCJ1c2VyX2lkIjo0LCJwaG9uZV9ubyI6Iis5MTk4MjExNDA0NzEiLCJpYXQiOjE2ODIwMjcyMjF9.ukBVOExxLnM3K5Kj00on4z7PLlhRlsZ-Df2nGt4f0hk',
//     // typeid: craqitDB.getItem('typeId'),
//     // orgSlug: craqitDB.getItem('subdomain') || environment.env,
//     // currency: craqitDB.getItem('currency') || environment.currency,
//     // countryCode: craqitDB.getItem('countryCode') || environment.countryCode,
//   },
// });

const config = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const orderDetails = async(orderId,cb = () => {}) => {
    //let config =  await getConfig();
   
    return axios
      .get(api.getOrderDetails+`?orderId=${orderId}`)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        return cb(false);
      });
  };


  export const getWeight = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getWeightSlabs+`?pageNo=${pageNo}`, config)
      .then(function (response) {
         if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getAllWeight = async(cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getAllWeightSlabs, config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getCountries = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getCountries+`?pageNo=${pageNo}`, config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const getAllCountries = async(cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getAllCountries, config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getZones = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getZones+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const getAllZones = async(cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getAllZones,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getZonalCountries = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getZonalCountries+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getAllZonalCountries = async(cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getAllZonalCountries,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getZonalZipCodes = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getZonalZipcodes+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getUsers = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getUsers+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getPriceSlabs = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getPriceSlabs+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  

  export const getZonalPriceSlabs = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getZonalPriceSlabs+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const getAllOrders = async(pageNo,cb) => {
    // let config =  await getConfig();
    return axios
      .get(api.getOrders+`?pageNo=${pageNo}`,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(response.data.data);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };


  export const assignAWBNO = async(orderId,awb,cb = () => {}) => {
    //let config =  await getConfig();
    return axios
      .put(api.assignAWBNO,{orderId:orderId,awbNumber:awb},config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        return cb(false);
      });
  };

  export const addPriceSlabs = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addPriceSlab,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const addZonalPriceSlabs = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addZonalPriceSlab,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const addZone = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addZone,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  
  export const addZonalZipcode = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addZonalZipcode,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const addCountries = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addCountries,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const addZonalCountries = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .post(api.addZonalCountries,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const updatePriceSlab = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .put(api.updatePriceSlab,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const updateOrder = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .put(api.updateOrder,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const updateZonalPriceSlab = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .put(api.updateZonalPriceSlab,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const updateCountry = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .put(api.updateCountry,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const updateZonalCountry = async(data,cb) => {
    // let config =  await getConfig();
    return axios
      .put(api.updateZonalCountry,data,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const removePriceSlab = async(id,cb) => {
    // let config =  await getConfig();
    return axios
      .delete(api.removePriceSlab+'?id='+id,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };
  export const removeZonalPriceSlab = async(id,cb) => {
    // let config =  await getConfig();
    return axios
      .delete(api.removeZonalPriceSlab+'?id='+id,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const removeZone = async(id,cb) => {
    // let config =  await getConfig();
    return axios
      .delete(api.removeZone+'?id='+id,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };

  export const removeZonalZipcode = async(id,cb) => {
    // let config =  await getConfig();
    return axios
      .delete(api.removeZonalZipcode+'?id='+id,config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.success === true) {
            return cb(true);
          } else {
            return cb(null);
          }
        }
      })
      .catch(function (error) {
        cb(false);
      });
  };