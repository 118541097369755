import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual login logic
    const token = 'your-token';
    localStorage.setItem('token', token);
    onLogin();
    navigate('/', { replace: true });
  };

  return (
    <div className='login-container'>
    <div className='login-form'>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Username" required value={username} onChange={(event) => setUsername(event.target.value)} />

        <br />
          <input type="password" placeholder="Password" value={password} required onChange={(event) => setPassword(event.target.value)} />
        <br />
        <button type="submit">Login</button>
      </form>
    </div>
    </div>
  );
};

export default Login;