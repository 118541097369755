import axios from 'axios';
import api from '../../../service/api';

// const getConfig = () => ({
//   headers: {
//     'device-type': Platform.OS,
//     'device-id': getUniqueId(),
//     'app-version': getVersion(),
//     token: craqitDB.getItem('token'),
//     typeid: craqitDB.getItem('typeId'),
//     orgSlug: craqitDB.getItem('subdomain') || environment.env,
//     currency: craqitDB.getItem('currency') || environment.currency,
//     countryCode: craqitDB.getItem('countryCode') || environment.countryCode,
//   },
// });
const config = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
};
export const getAllCountries = async() => {
  // let config =  await getConfig();
  return axios
    .get(api.getAllCountries,config)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.success === true) {
          return response.data.data;
        } else {
          return null;
        }
      }
    })
    .catch(function (error) {
      return false;
    });
};

export const getAllOrderStatus = async() => {
  // let config =  await getConfig();
  return axios
    .get(api.getAllOrderStatus,config)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.success === true) {
          return response.data.data;
        } else {
          return null;
        }
      }
    })
    .catch(function (error) {
      return false;
    });
};
export const getAllPaymentStatus = async() => {
  // let config =  await getConfig();
  return axios
    .get(api.getAllPaymentStatus,config)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.success === true) {
          return response.data.data;
        } else {
          return null;
        }
      }
    })
    .catch(function (error) {
      return false;
    });
};
export const getAllOrderStatusTracking = async() => {
  // let config =  await getConfig();
  return axios
    .get(api.getAllOrderStatusTracking,config)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.success === true) {
          return response.data.data;
        } else {
          return null;
        }
      }
    })
    .catch(function (error) {
      return false;
    });
};
export const getAllZonalCountries = async() => {
  // let config =  await getConfig();
  return axios
    .get(api.getAllCountries,config)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.success === true) {
          return response.data.data;
        } else {
          return null;
        }
      }
    })
    .catch(function (error) {
      return false;
    });
};