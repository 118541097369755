import React from 'react';
import { Button, message, Popconfirm } from 'antd';

const App = (props) => {
  const confirm = (e) => {
    props.confirmed(e);
  };

  const cancel = (e) => {
    message.error('Clicked No');
  };

  return (
    <Popconfirm
      title="Delete the record"
      okText="Yes"
      cancelText="No"
      onConfirm={confirm}
      onCancel={cancel}
    >
      <Button size='small' danger>Delete</Button>
    </Popconfirm>
  );
};

export default App;
