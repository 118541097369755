import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Main from './components/Main';
import {Provider} from 'react-redux';
import store from './redux/store';

const getToken = () => {
  // Replace this with your actual token retrieval logic
  return localStorage.getItem('token');
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: getToken() !== null,
    };
  }

  handleLogin = () => {
    this.setState({ loggedIn: true });
  };

  handleLogout = () => {
    localStorage.removeItem('token');
    this.setState({ loggedIn: false });
  };

  render() {
    const { loggedIn } = this.state;

    return (
      <Router>
        {loggedIn ? (
          <Provider store={store}>
          <MainRoutes onLogout={this.handleLogout} />
        </Provider>
        ) : (
          <LoginRoutes onLogin={this.handleLogin} />
        )}
      </Router>
    );
  }
}

const MainRoutes = ({ onLogout }) => {
  return (
    <Routes>
      <Route path="/" element={<Main onLogout={onLogout} />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const LoginRoutes = ({ onLogin }) => {
  return (
    <Routes>
      <Route path="/login" element={<Login onLogin={onLogin} />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default App;